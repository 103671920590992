<template>
	<div>
		<b-card title="Activity Log List">
			<b-row class="mb-2">
				<b-col cols="12" md="6">
				</b-col>
				<b-col cols="12" md="6">
					<form class="form-inline justify-content-end">
						<div class="d-flex">
							<div class="position-relative">
								<input 
									type="search"
									class="form-control"
									v-model="filter.keyword" 
									placeholder="Search: Feature" 
									aria-label="Search"
								>
							</div>
							<button 
								:disabled="!filter.keyword && !filter.email" 
								@click="filter.keyword = '' , filter.email = ''" 
								class="btn btn-outline-secondary ml-sm-2"
							>
								Reset
							</button>
						</div>
					</form>
				</b-col>
			</b-row>
			<b-table 
				striped 
				hover 
				:items="activity" 
				:fields="fields"
				:busy="isLoading"
				show-empty
				responsive
			>
				<template #table-busy>
					<div class="text-center text-secondary my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>
				
				<template v-slot:cell(no)="{ index }">
					{{ (currentPage - 1) * 10 + index + 1 }}
				</template>

				<template v-slot:cell(waktu_akses)="{ item }">
					{{ item.created_at }}
				</template>

				<template v-slot:cell(actions)="{ item }">
					<div class="d-flex" style="gap:12px;">
						<b-badge 
							variant="primary"
							class="badge-glow"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-info-icon`"
								icon="AlertCircleIcon"
								size="16"
								v-if="checkPermission('detail admin')"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-modal="modalId(item.uuid)"
								v-b-tooltip.hover.bottom="'Info Data'"
							/>
						</b-badge>
						<b-modal
							:id="'modal_' + item.uuid"
							centered
							title="Activity Log Detail"
							hide-footer
						>
							<form action="">
								<div class="form-group">
									<label>Nama</label>
									<input type="text" class="form-control" v-model="item.name" disabled>
								</div>
								<div class="form-group">
									<label>Email</label>
									<input type="email" class="form-control" v-model="item.email" disabled>
								</div>
								<div class="form-group">
									<label>IP Address</label>
									<input type="text" class="form-control" v-model="item.ip_address" disabled>
								</div>
								<div class="form-group">
									<label>URL</label>
									<input type="text" class="form-control" v-model="item.url" disabled>
								</div>
								<div class="form-group">
									<label>User Agent</label>
									<input type="text" class="form-control" v-model="item.user_agent" disabled>
								</div>
								<div class="form-group">
									<label>Payload</label>
									<textarea class="form-control text-capitalize" :value="JSON.stringify(item.content, null)" disabled/>
								</div>
								<div class="form-group">
									<label>Feature</label>
									<input type="text" class="form-control" v-model="item.feature" disabled>
								</div>
							</form>
						</b-modal>
					</div>
				</template>
			</b-table>
			<div
				v-if="result.total > 0"
				class="m-1"
			>
				<div class="row">
					<div class="col mb-1">
						<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
					</div>
					<div class="col">
						<pagination
							:data="result"
							:limit="4"
							align="right"
							@pagination-change-page="getData"
						/>
					</div>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
import { 
	BCard,
	BCardText,
	BTable,
	VBTooltip,
	BPagination,
	VBModal,
	BButton,
	BSpinner,
	BRow,
	BBadge,
	BCol
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import _ from 'lodash'

export default {
	components: {
		BCard,
		BCardText,
		BTable,
		BPagination,
		BButton,
		BSpinner,
		BRow,
		BBadge,
		BCol
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	directives: {
    'b-modal': VBModal,
	'b-tooltip': VBTooltip,
		Ripple,
	},
	setup() {
		return {
			checkPermission,
			successNotification, 
			errorNotification
		}
	},
	data() {
		return {
			currentPage: 1,
			result: {},
			isLoading: false,
			fields: ['no','name', 'email', 'feature', 'ip_address', 'waktu_akses', 'actions'],
			activity: [],
			filter: {
				keyword: '',
			},
		}
	},

	computed: {
		rows() {
			return this.activity.length
		}
	},

	methods: {
		title() {
			return `Activity Log`
		},
		modalId(id) {
			return 'modal_' + id;
		},
		getData(page) { //page = 1
			this.isLoading = true
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('/admin/activity-log/customer', {
				params: queryParams,
			})
				.then(response => {
				this.result = response.data.data
				this.activity = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			})
		},
	},

	created() {
		this.getData();
	}
}
</script>

<style>
	
</style>
